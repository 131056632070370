
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';

@Component({
  name: 'ShareFeedbackPage',
  components: {
    PageTitle
  },
})
export default class ShareFeedbackPage extends Vue {
  
}
